<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.listOfShipments') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="danger"
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnPdf()"
                >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :value.sync="preference"
                    v-model.trim="preference"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.see')"
                    :options="preferenceListFormatted"
                    @change="filterPreference"
                />
            </CCol>    
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    v-model.trim="VisitCraneId"
                    :value.sync="VisitCraneId"
                    @change="filterCrane"
                    :disabled = "disabledFilterByPreference"
                />
            </CCol>
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    :disabled = "disabledFilterByPreference"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getLoadList(Visit)"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
          </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                    :options="unitMeasureOptions"
                    required 
                />
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field pl-1">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-7 col-xl-7  text-right', input: 'col-sm-10 col-lg-5 col-xl-5 px-0'}"
                    :label="$t('label.loadPort')"
                    v-model="loadPortId"
                    :value.sync="loadPortId"
                    @change="filterLoadPort"
                    :options="portListOptions"
                    required 
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>

            <CCol sm="11" lg="12" xl="12" class="mb-2 ">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :getRowClass="getRowClass"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            rowSelection="single"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CModalExtended
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
        >   
            <CRow>
                <CCol sm="12">
                    <CRow class="m-1" >
                        <CCol sm="12" lg="12" xl="12">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-11 col-lg-11 col-xl-11 text-right col-form-label">{{$t('label.selectAll')}}</label>
                                <div class="col-sm-1 col-lg-1 col-xl-1 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="changeAll"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" v-if="FgGeneralCargo">
                            <div class="form-group row">
                                <label class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">LOCATION</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.location"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" v-if="FgGeneralCargo">
                            <div class="form-group row">
                                <label class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">HOLD</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.hold"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" v-if="FgGeneralCargo">
                            <div class="form-group row">
                                <label class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">HATCH COVER</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.hatchCover"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">ISO CODE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.iso_code"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">GROUP TYPE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.group_type"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DISCHARGE PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.pod"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">LOAD PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.loadPort"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DELIVERY PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.deliveryPort"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">WEIGTH</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.weigth"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CONFIRMED</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.FgConfirmed"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CONFIRMATION</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.ConfirmationDate"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CARRIER CODE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.shippinglinecode"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CARRIER</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.carrier"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">IMDG CODE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.imdg_code"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">TRANSHIPMENT PORT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.transhipment_port"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">VGM CERTIFICATE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.vgm_certificate"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">VGM</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.vgm"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">BOOKING</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.booking"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DEPARTURE SLOT</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.CodPosition"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <!--CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">SEAL 1</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Seal1"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">SEAL 2</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Seal2"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">SEAL 3</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Seal3"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">SEAL 4</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Seal4"
                                    />
                                </div>
                            </div>
                        </CCol-->
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">STATUS</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Status"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">SEALS</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Seals"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">CRANE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.CraneAlias"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DEVICE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.DeviceCode"
                                    />
                                </div>
                            </div>
                        </CCol>  
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">USER</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.TransaLogin"
                                    />
                                </div>
                            </div>
                        </CCol> 
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">YARD</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Yard"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">PROCEDENCE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.Procedence"
                                    />
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="4" lg="4" xl="4">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-9 col-lg-9 col-xl-9 text-right col-form-label">DIRECT DISCHARGE</label>
                                <div class="col-sm-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-end">
                                    <CSwitch
                                        color="watch"
                                        variant="3d"
                                        size="sm"
                                        type="checkbox"
                                        :checked.sync="columnsOption.DirectDischarge"
                                    />
                                </div>
                            </div>
                        </CCol>                      
                    </CRow>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="wipe"
                    @click="modalColumn = false"
                    class="m-2"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>


<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';

    //Data
    function data() {
        return {
            loadingOverlay: false,
            textSearch:'',
            gridOptions: null,
            rows: [],
            dataBuque:{
                CountryName:'',
                DatePlanning:'',
                VesselFlagRoute:'',
            },
            SizeChanged:50,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            changeAll:true,
            loadPortOptions:[],
            unitMeasureOptions:[],
            loadPortId:null,
            unitMeasureId:null,
            columnsOption:{
                location: true,
                hold: true,
                hatchCover: true,
                iso_code:true,
                group_type:true,
                pod:true,
                weigth:true,
                shippinglinecode:true,
                carrier:true,
                imdg_code:true,
                transhipment_port:true,
                booking:true,
                CodPosition:true,
                Seal1: true,
                Seal2: true,
                Seal3: true,
                Seal4: true,
                Seals: true,
                Status: true,
                loadPort: true,
                deliveryPort: true,
                vgm_certificate:true,
                vgm:true,
                FgConfirmed: true,
                ConfirmationDate:true,
                DeviceCode:true,
                TransaLogin: true,
                CraneAlias: true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            preference:1,
            preferenceOptions: [],
            nulo:'', 
            VisitCraneId: '',
            craneOptions: [],
            CraneAlias: '', 
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            previousDate: new Date(),
            laterDate: new Date(),
            start: false,
            disabledFilterByPreference: false,
        }
    }

    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }
    async function onBtnPdf(){
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        let rowDataFormated = [];
        if (this.FgGeneralCargo) {
            rowData.forEach(item =>{
                rowDataFormated.push({
                    id: item.id,
                    CodPosition: item.CodPosition,
                    siglas: item.siglas,
                    size: item.size,
                    iso_code: item.iso_code,
                    VesselUbicationName: item.VesselUbicationName ?? '',
                    VesselHoldName: item.VesselHoldName ?? '',
                    HatchCoverName: item.HatchCoverName ?? '',
                    Seals: item.Seals,
                    Status: item.Status,
                    shippinglinecode: item.shippinglinecode,
                    carrier: item.carrier,
                    imdg_code: item.imdg_code,
                    loadPort: item.loadPort,
                    pod: item.pod,
                    deliveryPort: item.deliveryPort,
                    transhipment_port: item.transhipment_port,
                    CraneAlias: item.CraneAlias,
                    group_type: item.group_type,
                    weigth: formatMilDecimal(parseFloat(item.weigth).toFixed(2)),
                    booking: item.booking,
                    vgm_certificate: item.vgm_certificate,
                    vgm: formatMilDecimal(parseFloat(item.vgm).toFixed(2)),
                    FgConfirmed: item.FgConfirmed,
                    ConfirmationDate: item.ConfirmationDate,
                    DeviceCode: item.DeviceCode,
                    TransaLogin: item.TransaLogin,
                    Procedence:item.Procedence,
                    YardNamePlanning:item.YardNamePlanning, 
                    FgDirect:item.FgDirect
                });   
            })
        } else {
            for (let i = 0; i < rowData.length; i++) {
                rowDataFormated.push({
                    id: rowData[i].id,
                    CodPosition: rowData[i].CodPosition,
                    siglas: rowData[i].siglas,
                    size: rowData[i].size,
                    iso_code: rowData[i].iso_code,
                    Seals: rowData[i].Seals,
                    Status: rowData[i].Status,
                    shippinglinecode: rowData[i].shippinglinecode,
                    carrier: rowData[i].carrier,
                    imdg_code: rowData[i].imdg_code,
                    loadPort: rowData[i].loadPort,
                    pod: rowData[i].pod,
                    deliveryPort: rowData[i].deliveryPort,
                    transhipment_port: rowData[i].transhipment_port,
                    CraneAlias: rowData[i].CraneAlias,
                    group_type: rowData[i].group_type,
                    weigth: formatMilDecimal(parseFloat(rowData[i].weigth).toFixed(2)),
                    booking: rowData[i].booking,
                    vgm_certificate: rowData[i].vgm_certificate,
                    vgm: formatMilDecimal(parseFloat(rowData[i].vgm).toFixed(2)),
                    FgConfirmed: rowData[i].FgConfirmed,
                    ConfirmationDate: rowData[i].ConfirmationDate,
                    DeviceCode: rowData[i].DeviceCode,
                    TransaLogin: rowData[i].TransaLogin,
                    Procedence:rowData[i].Procedence,
                    YardNamePlanning:rowData[i].YardNamePlanning, 
                    FgDirect:rowData[i].FgDirect
                    
                });   
            }
        }
        
        try{
            if(this.nulo!="null" && this.formatedItems.length !== 0){
                this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                await this.getPdf(rowDataFormated,this.$t('label.listOfShipments'));
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }else{
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });
            }
        } catch (error) {
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
        }
    }
    async function onBtnExport(valor) {
        /*
            this.gridApi = params.api;
            this.columnApi
        */
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;

        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            await this.getExcel(rowData,this.$t('label.listOfShipments'),valor,this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }
    function filterLoadPort(event){
        if(event.target.value !== '' && event.target.value !== null){
            this.loadPortId = event.target.value;
            /*this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('POST', 'VisitCargoLoadPort-list', {
                LoadPortId: event.target.value,
                EdiFileId: this.EdiFileId,
                VisitId: (this.Visit === undefined || this.Visit === null) ? '': this.Visit 
            },{ root: "CargoListJson"}).then(response => {
                this.rows = response.data.data;
                
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }).catch( err => {
                this.$store.state.visitas.messageError = err;
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }); */
        }
    }
    //funcion de carga de la data
    function getLoadList(Id) {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'VisitPortLoadReport-List?VisitId='+this.Visit).then(responseLoadPort => {
            this.loadPortOptions = responseLoadPort.data.data;

            if(this.loadPortId == null){
                this.loadPortId = this.branch.PortId;
            }
            let dateStart = '';
            let dateEnd = '';

            dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
            dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

            this.$http.ejecutar('POST', 'VisitCargoLoadPort-list', {
                UnitMeasureId: this.unitMeasureId,
                VisitId: this.Visit,
                CompanyBranchId: this.branch.CompanyBranchId,
                VisitCraneId:this.VisitCraneId,
                DateStart:dateStart,
                DateEnd:dateEnd,
                UserPreference:this.preference,
                LoadPortId: this.loadPortId,
            },{ root: "CargoListJson"}).then(response => {
                    this.rows = response.data.data;
                    this.nulo = String(response.data.data[0].Json);
                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }).catch( err => {
                this.$store.state.visitas.messageError = err;
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            });
        }).catch( err => {
            this.$store.state.visitas.messageError = err;
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
        });
    }

    async function getPreferenceList() {
        await this.$http.get("UserPreference-list")
        .then(response => {
            this.preferenceOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    async function getCraneList() {
        await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
        .then(response => {    
            this.craneOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }

    function filtroOpciones(data,value){
        let listado = [];
        for (let i = 0; i < data.length; i++) {    
            listado.push({
                id: data[i].UnitMeasureId,
                value: data[i].Value,
            })
        }

        const findUnitMeasure = listado.find( item => item.id == this.unitMeasureId);
        if(findUnitMeasure != undefined)
            return findUnitMeasure.value;
        else
            return 0;
    }

    async function filterCrane(e) {
        this.VisitCraneId=e.target.value;
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        this.textSearch="";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.unitMeasureId= '';
        this.disabledFilterByPreference= false;
        this.VisitCraneId= '';
        this.preference= 1;
        this.loadPortId= null;
        this.changeAll = true;
        this.rows = [];
        this.gridApi.refreshCells();
        this.SizeChanged = 50;
        this.gridApi.paginationSetPageSize(parseInt(this.SizeChanged));
        await this.getDateRange();
        await this.getPreferenceList();
        await this.getCraneList();
        await this.getUnitMeasureWeigthList();
        await this.getLoadList(this.Visit);
    }

    function filterPreference(e) {
        this.preference=e.target.value;
        if (this.preference == 3)
            this.disabledFilterByPreference= true;
        else
            this.disabledFilterByPreference= false;
    }
    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
    }

    async function getUnitMeasureWeigthList () {
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];

            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;
        }).catch( err => {  
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }

    function getRowClass(params) {
        if (params.node.data.FgConfirmed=="SI" || params.node.data.FgConfirmed=="YES") {
            return 'bg-confirmed'
        }
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }
    //computed
    function loadText(){
        if(this.loadPortId !== null){
            let loadtext = '';
            const Index = this.loadPortOptions.map(function(e) {
                return e.PortId; 
            }).indexOf(this.loadPortId);
            
            if(Index > -1){
                loadtext = this.loadPortOptions[Index].PortCode;
            }
            return loadtext;
        }else{  
            return '';
        }
    }
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                //pinned: 'left',
                minWidth: 70,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
        ]
        if (!this.FgGeneralCargo) {
            columnDefs.push({
                field: "CodPosition",
                headerName: "DEPARTURE SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 170,
                suppressMovable: true,
                lockPosition: true,
            });
        }
        columnDefs.push({
            field: "siglas",
            headerName: "CONTAINER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 160,
            //pinned: 'left',
        },
        {
            field: "size",
            headerName: "SIZE (FT)",
            filter: "agTextColumnFilter",
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: true,
            cellClass: 'center-cell-especial',
            minWidth: 160,
        });
        if(this.FgGeneralCargo && this.columnsOption.location){
            columnDefs.push({
                field: "VesselUbicationName",
                headerName: "LOCATION",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.FgGeneralCargo && this.columnsOption.hold){
            columnDefs.push({
                field: "VesselHoldName",
                headerName: "HOLD",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.FgGeneralCargo && this.columnsOption.hatchCover){
            columnDefs.push({
                field: "HatchCoverName",
                headerName: "HATCH COVER",
                filter: "agTextColumnFilter",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            });
        }
        if(this.columnsOption.iso_code){
            columnDefs.push({
                field: "iso_code",
                headerName: "ISO CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
       /* if(this.columnsOption.Seal1){
            columnDefs.push({
                field: "Seal1",
                headerName: "SEAL 1", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 100,
            });
        }
        if(this.columnsOption.Seal2){
            columnDefs.push({
                field: "Seal2",
                headerName: "SEAL 2", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 100,
            });
        }
        if(this.columnsOption.Seal3){
            columnDefs.push({
                field: "Seal3",
                headerName: "SEAL 3", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 100,
            });
        }
        if(this.columnsOption.Seal4){
            columnDefs.push({
                field: "Seal4",
                headerName: "SEAL 4", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 100,
            });
        } */
        if(this.columnsOption.Seals){
            columnDefs.push({
                field: "Seals",
                headerName: "SEALS", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 100,
            });
        }
        if(this.columnsOption.Status){
            columnDefs.push({
                field: "Status",
                headerName: "STATUS",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.shippinglinecode){
            columnDefs.push({
                field: "shippinglinecode",
                headerName: "CARRIER CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.carrier){
            columnDefs.push({
                field: "carrier",
                headerName: "CARRIER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.imdg_code){
            columnDefs.push({
                field: "imdg_code",
                headerName: "IMDG CODE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.loadPort){
            columnDefs.push({
                field: "loadPort",
                headerName: "LOAD PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.pod){
            columnDefs.push({
                field: "pod",
                headerName: "DISCHARGE PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.deliveryPort){
            columnDefs.push({
                field: "deliveryPort",
                headerName: "DELIVERY PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.transhipment_port){
            columnDefs.push({
                field: "transhipment_port",
                headerName: "TRANSHIPMENT PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.CraneAlias){
            columnDefs.push({
                field: "CraneAlias",
                headerName: "CRANE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.group_type){
            columnDefs.push({
                field: "group_type",
                headerName: "GROUP TYPE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.weigth){
            columnDefs.push({
                field: "weigth",
                headerName: "WEIGTH"+' ('+this.filterText+')',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
            });
        }
        if(this.columnsOption.booking){
            columnDefs.push({
                field: "booking",
                headerName: "BOOKING", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }

        if(this.columnsOption.vgm_certificate){
            columnDefs.push({
                field: "vgm_certificate",
                headerName: "VGM CERTIFICATE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.vgm){
            columnDefs.push({
                field: "vgm",
                headerName: "VGM"+' ('+this.filterText+')', 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
                filter: "agNumberColumnFilter",
            });
        }
        
        if(this.columnsOption.FgConfirmed){
            columnDefs.push({
                field: "FgConfirmed",
                headerName: "CONFIRMED", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.ConfirmationDate){
            columnDefs.push({
                field: "ConfirmationDate",
                headerName: "CONFIRMATION", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.DeviceCode){
            columnDefs.push({
                field: "DeviceCode",
                headerName: "DEVICE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }             
        if(this.columnsOption.TransaLogin){
            columnDefs.push({
                field: "TransaLogin",
                headerName: "USER", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 150,
            });
        }
        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }   
    return columnDefs;
    }
    function formatedItems() {
        let computedItems = [];
        let _this = this;
        
        if(this.rows.length !== 0  && this.nulo!="null"){
            let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            this.rows.map(function(item,index){
                //Weigth: Weigth  Y VGM
                let weigth = _this.filtroOpciones(item.CargoJson[0].WeigthJson,_this.unitMeasureId);
                let vgm = _this.filtroOpciones(item.CargoJson[0].VgmJson,_this.unitMeasureId);

                computedItems.push({
                    id: index+1,
                    VisitCargoId: item.VisitCargoId ? item.VisitCargoId : '',
                    siglas: item.ContainerCode ? item.ContainerCode : '',
                    sizeid: item.CargoJson[0].SizeId ? item.CargoJson[0].SizeId : null,
                    size: item.CargoJson[0].TpCargoDetailCode ? item.CargoJson[0].TpCargoDetailCode : '',
                    statusid: item.CargoJson[0].TpCargoStatusId ? item.CargoJson[0].TpCargoStatusId : null,
                    status: item.CargoJson[0].TpCargoStatusName ? item.CargoJson[0].TpCargoStatusName : '',
                    CraneAlias: item.CraneAlias ? item.CraneAlias : '',
                    VesselUbicationName: item['VesselUbicationName'+_lang] ?? '',
                    VesselHoldName: item.VesselHoldName ?? '',
                    HatchCoverName: item.HatchCoverName ?? '',
                    iso_code: item.CargoJson[0].IsoCode ? item.CargoJson[0].IsoCode : '',
                    group_type: item.CargoJson[0].TpCargoName ? item.CargoJson[0].TpCargoName : '',
                    podid: item.CargoJson[0].DischargePortId ? item.CargoJson[0].DischargePortId : null,
                    pod: item.CargoJson[0].DischargePort ? item.CargoJson[0].DischargePort : '',
                    unitMeasureId: _this.unitMeasureId ? _this.unitMeasureId : null,
                    weigth: weigth ? weigth : 0,
                    shippinglinecode: item.CargoJson[0].ShippingLineCode ? item.CargoJson[0].ShippingLineCode : '',
                    carrier: item.CargoJson[0].ShippingLineName ? item.CargoJson[0].ShippingLineName : '',
                    imdg_code: item.CargoJson[0].ImdgCode ? item.CargoJson[0].ImdgCode : '',
                    transhipment_portid: item.CargoJson[0].TranshipmentPortId ? item.CargoJson[0].TranshipmentPortId : null,
                    transhipment_port: item.CargoJson[0].TranshipmentPort ? item.CargoJson[0].TranshipmentPort : '',
                    booking_number: item.CargoJson[0].BookingNumber ? item.CargoJson[0].BookingNumber : '',
                    CodPosition: item.CargoJson[0].BaySlot ? item.CargoJson[0].BaySlot : '',
                    Seal1: item.CargoJson[0].Seal1 ? item.CargoJson[0].Seal1 : '',
                    Seal2: item.CargoJson[0].Seal2 ? item.CargoJson[0].Seal2 : '',
                    Seal3: item.CargoJson[0].Seal3 ? item.CargoJson[0].Seal3 : '',
                    Seal4: item.CargoJson[0].Seal4 ? item.CargoJson[0].Seal4 : '',
                    Seals: item.CargoJson[0].Seals ? item.CargoJson[0].Seals : '',
                    Status: item.CargoJson[0].Status ? item.CargoJson[0].Status : '',
                    loadPort: item.CargoJson[0].LoadPort ? item.CargoJson[0].LoadPort : '',
                    deliveryPort: item.CargoJson[0].DeliveryPort ? item.CargoJson[0].DeliveryPort : '',
                    vgm_certificate: item.CargoJson[0].VgmCertificate ? item.CargoJson[0].VgmCertificate : '',
                    vgm: vgm ? vgm : 0,
                    FgConfirmed: item.FgConfirmed === true ? _this.$t('label.yes') : 'NO',
                    ConfirmationDate: item.ConfirmationDate ? DateFormater.formatDateTimeWithSlash(item.ConfirmationDate) : '',
                    DeviceCode: item.DeviceCode ? item.DeviceCode : '',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        return computedItems.filter(item => {
            return item
        }) 
    }
    function filterText(){
        if(this.unitMeasureId === '' || this.unitMeasureId === null ){
            return 'TON';
        }else{
            let text = '';
            text = this.unitMeasureOptions.find( item => item.value === this.unitMeasureId );
            /*this.unitMeasureOptions.map(async (e) => {
                if(this.unitMeasureId === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })*/
            return text.label;
        }
    }

    function portListOptions() {
        if (this.loadPortOptions.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            let chart = [];
            this.loadPortOptions.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortCode,
                });
            });
            return chart;
        }
    }


    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    function dropItemReport(newQuestion,oldQuestion){
        if(newQuestion === 27){
            this.SizeChanged = 50;
            this.gridApi.paginationSetPageSize(parseInt(this.SizeChanged));
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
            this.changeAll = true;
            this.unitMeasureId='';

            if(this.Visit !== ''){
                this.refreshComponent();
            }
        }else if(oldQuestion === 27){
            this.unitMeasureId='';
            this.SizeChanged = 50;
            this.gridApi.paginationSetPageSize(parseInt(this.SizeChanged));
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
        }
    }
    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }

    function changeAll(newQuestion){
        //columna 1
        this.columnsOption.location=newQuestion;
        this.columnsOption.hold=newQuestion;
        this.columnsOption.hatchCover=newQuestion;
        this.columnsOption.iso_code=newQuestion;
        this.columnsOption.group_type=newQuestion;
        this.columnsOption.pod=newQuestion;
        this.columnsOption.weigth=newQuestion;
        this.columnsOption.carrier=newQuestion;
        this.columnsOption.shippinglinecode=newQuestion;

        this.columnsOption.imdg_code=newQuestion;
        this.columnsOption.transhipment_port=newQuestion;

        this.columnsOption.booking=newQuestion;
        this.columnsOption.CodPosition=newQuestion;
        this.columnsOption.Seal1=newQuestion;
        this.columnsOption.Seal2=newQuestion;
        this.columnsOption.Seal3=newQuestion;
        this.columnsOption.Seal4=newQuestion;
        this.columnsOption.Seals=newQuestion;
        this.columnsOption.Status=newQuestion;
        this.columnsOption.loadPort=newQuestion;
        this.columnsOption.deliveryPort=newQuestion;
        this.columnsOption.vgm_certificate=newQuestion;
        //columna 2
        this.columnsOption.vgm=newQuestion;
        this.columnsOption.FgConfirmed=newQuestion;
        this.columnsOption.ConfirmationDate=newQuestion;
        this.columnsOption.DeviceCode=newQuestion;
        this.columnsOption.TransaLogin=newQuestion;

        this.columnsOption.Yard=newQuestion;
        this.columnsOption.Procedence=newQuestion;
        this.columnsOption.DirectDischarge=newQuestion;
    }


    function preferenceListFormatted(){
        let _this = this.$i18n.locale;
        return this.preferenceOptions.map((item) => Object.assign({}, item, {
                value: item.Value,
                label: _this=='en' ? item.UserPreferenceNameEn : item.UserPreferenceNameEs,
            }));
    }

    function craneListFormatted(){
        if(this.craneOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.ALL'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.ALL'),
            }]
            this.craneOptions.map(function(e){
                
                    chart.push({
                        value: e.VisitCraneId, 
                        label: e.CraneAlias,
                    })
                
            })
            return chart;
        }
    }

    export default {
        name: "load-list",
        data,
        beforeMount,
        methods:{
            getLoadList,
            onBtnPdf,
            onBtnExport,
            onGridReady,
            filtroOpciones,
            filterLoadPort,
            getCraneList,
            filterCrane,
            validateDateRange, 
           // getData,
            refreshComponent,
            getPreferenceList,
            filterPreference,
            filterUnitMeasureWeight,
            getUnitMeasureWeigthList,
            getRowClass,
            getDateRange,
        },
        mixins: [ReportesVisitas, AgGrid],
        computed:{
            loadText,
            columnDefs,
            formatedItems,
            portListOptions,
            filterText,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,                
                branch: state => state.auth.branch,
                itinerarySelected: state => state.visitas.itinerarySelected,
                FgGeneralCargo: state => state.visitas.FgGeneralCargo,
            }),
            preferenceListFormatted,
            craneListFormatted,
        },
        watch:{
            textSearch,
            dropItemReport,
            SizeChanged,
            changeAll,
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
    .bg-confirmed{
        background: #7bdf9b !important;
    }
</style>